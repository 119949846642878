import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { rgba } from "polished";

import { Section, Card } from "../../elements/frontend/src/components";
import {
  useCustomers,
  // useAccounts,
  useTransactions,
  useClients,
} from "../../elements/frontend/src/hooks";

import Preview from "./Preview/Preview";
import { OverviewTable } from "../OverviewTable";
import { DefaultColumnFilter, SelectColumnFilter } from "../OverviewTable/helper";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { useNavigate } from "react-router-dom";
import { allFieldsAreValid } from "./hooks/allFieldsValid";
import { DashbordContext } from "../../views/Private/Home";
import { Typography } from "@mui/material";

const Overview = () => {
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();
  // const { getDocumentPlatform } = useDocuments();
  const navigate = useNavigate();
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dashboardContext = useContext(DashbordContext);
  const { getAllCustomers, updateCustomer, getCustomerById } = useCustomers();
  const { getClient } = useClients();
  const { updateTransaction, getTransactionById, getAllTransactions } = useTransactions();

  const dispatch = useDispatch();
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  //const { data, isLoading } = useGetCategoriesQuery();
  const [newRegisteredCustomers, setNewRegisteredCustomers] = useState(false);
  const [supervisorAssignedTransactions, setSupervisorAssignedTransactions] = useState([]);
  const [noneSupervisorAssignedTransactions, setNoneSupervisorAssignedTransactions] =
    useState(false);
  const [statusChangedTransactions, setStatusChangedTransactions] = useState([]);
  const [loadingNewCustomers, setLoadingNewCustomers] = useState(false);
  const [loadingStatusChanged, setLoadingStatusChanged] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [initialFetch, setInitialFetch] = useState(true);
  const [reloadLocal, setReloadLocal] = useState(true);
  const [finalRegisters, setFinalRegisters] = useState([]);
  const finalRegistersMemo = useMemo(() => {
    if (finalRegisters) {
      return [...finalRegisters];
    }
  }, [finalRegisters]);

  const testMode = dashboardContext.testing_mode;

  // Resetting all states after swithcing between testing mode and normal mode
  useEffect(() => {
    setLoadingNewCustomers(true);
    setLoadingStatusChanged(true);
    setLoadingTransactions(true);
    setFinalRegisters([]);
    setStatusChangedTransactions([]);
    setNewRegisteredCustomers(false);
    setSupervisorAssignedTransactions([]);
    setNoneSupervisorAssignedTransactions(false);
    setInitialFetch(true);
    setReloadLocal(true);
  }, [testMode]);
  // stepsObject to assign text as subheading for Treeview from locales
  const stepsObject = t("transaction_tables.process_flow.steps", {
    returnObjects: true,
  });

  // to assign text from locales for investor in new registered customers
  const investorObject = t("platform/common:content.overview.new_registered_customers.investor", {
    returnObjects: true,
  });
  // to assign text from locales for advisor in new registered customers
  const advisorObject = t("platform/common:content.overview.new_registered_customers.advisor", {
    returnObjects: true,
  });

  // to assign text from locales for investor/advisor type in new registered customers
  const typeObject = t("platform/common:content.overview.new_registered_customers.type", {
    returnObjects: true,
  });

  // to get all newly registered advisors/investors
  useEffect(() => {
    if (newRegisteredCustomers === false) {
      setLoadingNewCustomers(true);
      getAllCustomers()
        .then((response) => {
          // filters testaccounts
          const filter = response.customers.filter((customer) => {
            return customer.name.toLowerCase().includes("test" || "testing") === testMode;
          });
          setNewRegisteredCustomers(filter);
        })
        .catch(() => {
          setLoadingNewCustomers(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testMode, newRegisteredCustomers]);

  // to get all account transactions
  useEffect(() => {
    setLoadingTransactions(true);
    setLoadingStatusChanged(true);

    getAllTransactions()
      .then((response) => {
        const supervisorAssignedTransactions = response.transactions
          .filter((item) => item.state)
          .filter((item) => item.state.steps.supervisor.completed)
          .filter((item) => item.transaction_id)
          .map((item) => ({
            client_name: item.client_name,
            last_action: stepsObject[item.state.status.last_step],
            date: new Date(item.state.status.last_step_timestamp).toLocaleDateString(),
            next_action: stepsObject[item.state.status.next_steps[0]],
            assigned_supervisor: item?.supervisor?.user_name ? item?.supervisor?.user_name : "",
            assigned_advisor: item?.advisor?.customer_name
              ? item.advisor?.customer_name
              : t("transaction_tables.none"),
            assigned_lawyer: item?.lawyer?.customer_name,
            transaction_id: item.transaction_id,
            supervisor: item.supervisor,
            advisor: item.advisor,
            isAdvisorClient: !item.state.stages.hasOwnProperty("adviser_matching"), // Advisor client transaction does not have "advisor_matching" stage
          }));
        const noneSupervisorAssignedTransactions = response.transactions
          .filter((item) => item.state)
          .filter((item) => !item.state.steps.supervisor.completed)
          .map((item) => ({
            id: item.client_id,
            name: item.client_name,
            last_action: stepsObject[item.state.status.last_step],
            date: new Date(item.state.status.last_step_timestamp).toLocaleDateString(),
            next_action: stepsObject[item.state.status.next_steps[0]],
            type: typeObject.transaction,
            next_step: "supervisor",
            steps: item.state.steps,
            supervisor: item.supervisor,
            transaction_id: item.transaction_id,
            customer_id: item.customer_id,
            newTransaction: true,
          }));

        // filter test accouts
        const assignedFilter = supervisorAssignedTransactions.filter((transaction) => {
          return transaction.client_name.toLowerCase().includes("test" || "testing") === testMode;
        });
        const noneAssignedFilter = noneSupervisorAssignedTransactions.filter((transaction) => {
          return transaction.name.toLowerCase().includes("test" || "testing") === testMode;
        });
        setSupervisorAssignedTransactions(assignedFilter);
        setNoneSupervisorAssignedTransactions(noneAssignedFilter);
        setLoadingTransactions(false);
      })
      .catch(() => {
        setLoadingTransactions(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testMode]);

  // to compare supervisorAssignedTransactions with "allApprovedTransactions" from localStorage, to get status changes and list them in table-2
  useEffect(() => {
    if (supervisorAssignedTransactions.length > 0 && reloadLocal) {
      const identifier = testMode ? "allApprovedTest" : "allApprovedTransactions";
      const data = localStorage.getItem(identifier);
      const transactionsFromLocalStorage = data ? JSON.parse(data) : [];

      let allStatusChangedTransactions = [];
      if (transactionsFromLocalStorage.length > 0) {
        allStatusChangedTransactions = supervisorAssignedTransactions.filter((transaction) => {
          const transactionInLocalStorage = transactionsFromLocalStorage?.find(
            (item) => item.transaction_id === transaction.transaction_id
          );

          if (transactionInLocalStorage === undefined) {
            return true;
          } else return transactionInLocalStorage.last_action !== transaction.last_action;
        });
      } else {
        allStatusChangedTransactions = [];
        localStorage.setItem(identifier, JSON.stringify(supervisorAssignedTransactions));
      }
      setStatusChangedTransactions(allStatusChangedTransactions);
      setReloadLocal(false);
      setLoadingStatusChanged(false);
    }
  }, [supervisorAssignedTransactions, testMode, reloadLocal]);

  // in order to prevent duplication, need this usememo, and assigned to Table-1
  const newRegisteredCustomersTransactions = useMemo(() => {
    const resArray = [];
    if (finalRegistersMemo) {
      finalRegistersMemo.forEach((item) => {
        const i = resArray.findIndex((x) => x.id === item.id);
        if (i <= -1) {
          resArray.push({ ...item });
        }
      });
    }
    return resArray;
  }, [finalRegistersMemo]);

  const fetchExtraData = useCallback(
    (item) => {
      if (item.type !== "Transaction") {
        return getCustomerById(item.customer_id).then((response) => {
          const customer = response.customer;
          const type = customer.subscription.product_id;
          const next_action =
            type === "advisor"
              ? advisorObject[customer.state.status.next_steps[0]]
              : investorObject[customer.state.status.next_steps[0]];

          const last_action =
            type === "advisor"
              ? advisorObject[customer.state.status.last_step]
              : investorObject[customer.state.status.last_step];

          const result = {
            id: customer.customer_id,
            last_action,
            date: new Date(customer.state.status.last_step_timestamp).toLocaleDateString(),
            next_action,
            type: typeObject[type],
            next_step: customer.state.status.next_steps[0],
            steps: customer.state.steps,
          };

          return {
            ...customer,
            ...result,
          };
        });
      } else {
        return item;
      }
    },
    [getCustomerById, investorObject, advisorObject, typeObject]
  );

  useEffect(() => {
    if (
      finalRegisters.length === 0 &&
      initialFetch &&
      newRegisteredCustomers &&
      noneSupervisorAssignedTransactions
    ) {
      const initial =
        newRegisteredCustomers && noneSupervisorAssignedTransactions
          ? [...newRegisteredCustomers, ...noneSupervisorAssignedTransactions]
          : newRegisteredCustomers
          ? [...newRegisteredCustomers]
          : noneSupervisorAssignedTransactions
          ? [...noneSupervisorAssignedTransactions]
          : [];

      if (initial.length === 0) {
        setLoadingNewCustomers(false);
      } else {
        Promise.all(initial.slice(0, 10).map((item) => fetchExtraData(item)))
          .then((data) => {
            setFinalRegisters((prev) => [...prev, ...data]);
          })
          .catch(() => setLoadingNewCustomers(false));
      }
    }
  }, [
    initialFetch,
    newRegisteredCustomers,
    noneSupervisorAssignedTransactions,
    finalRegisters,
    fetchExtraData,
    getCustomerById,
    getTransactionById,
    getClient,
  ]);

  useEffect(() => {
    if (
      finalRegisters.length > 0 &&
      initialFetch &&
      newRegisteredCustomers &&
      noneSupervisorAssignedTransactions
    ) {
      const initial =
        newRegisteredCustomers && noneSupervisorAssignedTransactions
          ? [...newRegisteredCustomers, ...noneSupervisorAssignedTransactions]
          : newRegisteredCustomers
          ? [...newRegisteredCustomers]
          : noneSupervisorAssignedTransactions
          ? [...noneSupervisorAssignedTransactions]
          : [];

      Promise.all(initial.slice(10, initial.length).map((item) => fetchExtraData(item)))
        .then((data) => {
          setFinalRegisters((prev) => [...prev, ...data]);
          setLoadingNewCustomers(false);
          setInitialFetch(false);
        })
        .catch(() => setLoadingNewCustomers(false));
    }
  }, [
    initialFetch,
    newRegisteredCustomers,
    noneSupervisorAssignedTransactions,
    finalRegisters,
    fetchExtraData,
    getCustomerById,
    getTransactionById,
    getClient,
  ]);

  // to handle change event of checkbox in newRegsteredCustomers table
  const handleNewRegisteredCheckboxChange = (
    e,
    step,
    customerId,
    supervisor = {},
    newTransaction = false,
    transactionId = ""
  ) => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      // const checked = e.target.checked;

      if (newTransaction) {
        // This part is for transactions to assign supervisor user in Table-1.
        // we assign currentUser.user

        const objectToUpdate = {
          supervisor: {
            ...supervisor,
            user_id: currentUser.user.user_id,
          },
          metadata: [
            {
              scope: "state",
              data: {
                supervisor: true,
              },
            },
          ],
        };

        // update transaction in DB
        updateTransaction(transactionId, objectToUpdate).then((response) => {
          if (response) {
            window.location.reload();
          }
        });
      } else {
        // This part is for advisors/investors to update their steps in Table-1.
        const objectToUpdate = {
          metadata: [
            {
              scope: "state",
              data: {
                [step]: true,
              },
            },
          ],
        };

        // update customer in DB
        updateCustomer(customerId, objectToUpdate).then((response) => {
          if (response) {
            if (response.customer.state.steps.approved.completed) {
              window.location.reload();
            } else {
              // update customer in state newRegisteredCustomers
              const newCustomers = finalRegisters.map((item) => {
                if (item.id === customerId) {
                  const type = response.customer.subscription.product_id;
                  const next_action =
                    type === "advisor"
                      ? advisorObject[response.customer.state.status.next_steps[0]]
                      : investorObject[response.customer.state.status.next_steps[0]];

                  const last_action =
                    type === "advisor"
                      ? advisorObject[response.customer.state.status.last_step]
                      : investorObject[response.customer.state.status.last_step];

                  return {
                    ...item,
                    id: response.customer.customer_id,
                    name: response.customer.name,
                    last_action,
                    date: new Date(
                      response.customer.state.status.last_step_timestamp
                    ).toLocaleDateString(),
                    next_action,
                    type: typeObject[type],
                    next_step: response.customer.state.status.next_steps[0],
                    steps: response.customer.state.steps,
                    status: response.customer.state.status,
                  };
                }
                return item;
              });

              setFinalRegisters(newCustomers);
              dispatch(setCurrentUser({ loading: false }));
            }
          }
        });
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };
  // table_1 columns
  const table_1_columns = useMemo(
    () => [
      {
        Header: t("transaction_tables.columns.name"),
        accessor: "name",
        Filter: ({ column }) => (
          <DefaultColumnFilter
            column={column}
            placeholder={t("de/common:document_table.search_by_name")}
          />
        ),
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
        Filter: ({ column }) => (
          <DefaultColumnFilter column={column} placeholder={t("de/common:document_table.format")} />
        ),
      },
      {
        Header: t("transaction_tables.columns.next_action"),
        accessor: "next_action",
        //Filter: SelectColumnFilter,
        filter: "date",
      },
      {
        Header: t("transaction_tables.columns.type"),
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  // table_2 columns
  const table_2_columns = useMemo(
    () => [
      {
        Header: t("transaction_tables.columns.company_name"),
        accessor: "client_name",
        Filter: ({ column }) => (
          <DefaultColumnFilter
            column={column}
            placeholder={t("de/common:document_table.search_by_name")}
          />
        ),
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
        Filter: ({ column }) => (
          <DefaultColumnFilter column={column} placeholder={t("de/common:document_table.format")} />
        ),
      },
      {
        Header: t("transaction_tables.columns.next_action"),
        accessor: "next_action",
        //Filter: SelectColumnFilter,
        filter: "date",
      },
      {
        Header: t("transaction_tables.columns.supervisor"),
        accessor: "assigned_supervisor",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.advisor"),
        accessor: "assigned_advisor",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      // {
      //   Header: t("transaction_tables.columns.lawyer"),
      //   accessor: "assigned_lawyer",
      //   Filter: SelectColumnFilter,
      //   filter: "includes",
      // },
    ],
    [t]
  );

  // table_3 columns
  const table_3_columns = useMemo(
    () => [
      {
        Header: t("transaction_tables.columns.company_name"),
        accessor: "client_name",
        Filter: ({ column }) => (
          <DefaultColumnFilter
            column={column}
            placeholder={t("de/common:document_table.search_by_name")}
          />
        ),
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },

      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
        Filter: ({ column }) => (
          <DefaultColumnFilter column={column} placeholder={t("de/common:document_table.format")} />
        ),
      },
      {
        Header: t("transaction_tables.columns.advisor"),
        accessor: "assigned_advisor",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.supervisor"),
        accessor: "assigned_supervisor",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.lawyer"),
        accessor: "assigned_lawyer",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
    ],
    [t]
  );

  // to manage the sidebar STATUS step, which one will be visible or hided
  const handleStatus = (id) => {
    navigate(`${id}/status`);
  };

  // to manage the sidebar DETAILS step, which one will be visible or hided
  const handleDetails = (id) => {
    navigate(`${id}/details`);
  };

  const handleShowPreview = (customer, index) => {
    setSelectedCustomer({ customer: customer, index: index });
    setOpenPreview(true);
  };

  // to sync localStorage with the supervisorAssignedTransactions including last changes
  const handleSyncLocalStorage = (transaction_id) => {
    dispatch(setCurrentUser({ loading: true }));
    const key = testMode ? "allApprovedTest" : "allApprovedTransactions";
    const data = localStorage.getItem(key);
    const local = data ? JSON.parse(data) : [];
    const existsInLocal = local.find((item) => item.transaction_id === transaction_id);
    let newValue = [];
    if (existsInLocal) {
      newValue = local.map((item) => {
        if (item.transaction_id === transaction_id) {
          return supervisorAssignedTransactions.find(
            (item2) => item2.transaction_id === transaction_id
          );
        }
        return item;
      });
    } else {
      const newTransaction = supervisorAssignedTransactions.find(
        (item) => item.transaction_id === transaction_id
      );
      newValue = [...local, newTransaction];
    }
    const value = JSON.stringify(newValue);
    localStorage.setItem(key, value);
    setStatusChangedTransactions([]);
    setReloadLocal(true);
    dispatch(setCurrentUser({ loading: false }));
  };

  const handleSyncAllLocalStorage = () => {
    dispatch(setCurrentUser({ loading: true }));
    const key = testMode ? "allApprovedTest" : "allApprovedTransactions";
    const value = JSON.stringify(supervisorAssignedTransactions);
    localStorage.setItem(key, value);
    setStatusChangedTransactions([]);
    setReloadLocal(true);
    dispatch(setCurrentUser({ loading: false }));
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
      }}
    >
      {/* Table - 1 */}
      <Section title={t("platform/common:content.overview.new_registered_customers.headline")}>
        <Box
          sx={{
            boxShadow: `0.1rem 0.1rem 0.6rem ${rgba(theme.palette.greys.fourth.main, 0.4)}`,
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "25px 20px",
            [theme.breakpoints.up("md")]: {
              padding: openPreview ? "0px 0px 5px 0px" : "30px 20px",
            },
            width: "100%",
          }}
        >
          <Box style={{ display: openPreview ? "none" : "block", width: "100%" }}>
            <OverviewTable
              style={{ margin: "0px", width: "100%" }}
              columns={table_1_columns}
              data={newRegisteredCustomersTransactions}
              newRegister={true}
              loading={loadingNewCustomers}
              handleNewRegisteredCheckboxChange={handleNewRegisteredCheckboxChange}
              handleShowPreview={handleShowPreview}
              handleShowStatus={handleStatus}
              handleShowDetails={handleDetails}
              handleSyncLocalStorage={handleSyncLocalStorage}
              allFieldsAreValid={allFieldsAreValid}
            />
          </Box>
          <Preview
            openPreview={openPreview}
            setOpenPreview={setOpenPreview}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setFinalRegisters={setFinalRegisters}
            customerOriginal={
              finalRegistersMemo && selectedCustomer.customer
                ? finalRegistersMemo.find((item) => item.id === selectedCustomer.customer.id)
                : false
            }
            handleNewRegisteredCheckboxChange={handleNewRegisteredCheckboxChange}
            testMode={testMode}
          />
        </Box>
      </Section>

      {/* Table - 2 */}

      <Section title={t("platform/common:content.overview.actual_status_change.headline")}>
        <Card>
          {statusChangedTransactions.length > 0 || loadingStatusChanged ? (
            <OverviewTable
              columns={table_2_columns}
              data={statusChangedTransactions}
              handleShowStatus={handleStatus}
              handleShowDetails={handleDetails}
              loading={loadingStatusChanged}
              statusChange={true}
              handleSyncLocalStorage={handleSyncLocalStorage}
              handleSyncAllLocalStorage={handleSyncAllLocalStorage}
              testMode={testMode}
            />
          ) : (
            <Typography>
              {t("platform/common:content.overview.actual_status_change.no_transaction")}
            </Typography>
          )}
        </Card>
      </Section>

      {/* Table - 3 */}

      <Section title={t("platform/common:content.overview.all_transactions.headline")}>
        <Card>
          <OverviewTable
            columns={table_3_columns}
            data={supervisorAssignedTransactions}
            handleShowStatus={handleStatus}
            handleShowDetails={handleDetails}
            loading={loadingTransactions}
            testMode={testMode}
          />
        </Card>
      </Section>
    </Box>
  );
};

export default Overview;
